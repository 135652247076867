<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <!-- <el-button plain @click="exportClick()" style="float:right">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;
    return {
      nav: [{ name: "历史标段" }],
      common_getDistinctManageUnit: [true, 0, null], //主管单位下拉
      table: {
        api: vm.$api_hw.districtInfo_bidManageHistoryPage,
        query: {
          bidManageUnit: null, // 主管单位
          queryContent: null // 标段标号/标段名称（模糊查询）
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "select",
            title: "主管单位",
            model: "bidManageUnit",
            placeholder: "请选择",
            option: []
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "标段编号/标段名称"
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "标段编号",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.previewDistrictDetails.bind(this, ctx.row)
                    }
                  },
                  ctx.row.bidNum || "- -"
                )
              ]);
            }
          },
          {
            title: "标段名称",
            // width: "180px",
            key: "bidName"
          },
          {
            title: "养护企业",
            key: "bidMaintainUnit"
            // width: "160px"
          },
          {
            title: "主管单位",
            // width: "120px",
            key: "bidManageUnit"
          },
          {
            title: "面积（m²）",
            // width: "180px",
            key: "bidContractArea"
          },
          {
            title: "项目经理",
            key: "bidPm"
          },
          {
            title: "项目经理联系方式",
            key: "bidPmPhone"
          }
        ]
      }
    };
  },
  methods: {
    /**
     * 查看标段信息
     */
    previewDistrictDetails(val) {
      this.$router.push({
        path: "/district-management/district-info/district-details",
        query: { id: val.bidId }
      });
    },
    //导出
    exportClick() {}
  },
  async activated() {
    await this.$search(this.table);
  }
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
